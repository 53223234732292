import BaseBean from "@/utils/BaseBean";
import config from "@/utils/config";

export interface IRegisterDataObj {
    utilInst:RegisterUtil
    refMap:Map<string,any>
    regStep:number
    organization:any
    person:any
}

export default class RegisterUtil extends BaseBean{
    public dataObj:IRegisterDataObj

    constructor(proxy:any,dataObj:IRegisterDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    public async doRegister():Promise<void>{
        let res = await this.utils.Api.register({corpInfo:this.dataObj.organization.form,personInfo:this.dataObj.person.form});
        if(res.result){
            this.utils.Tools.success({message:this.proxy.$t('register.regSuccess')});
            this.dataObj.regStep++;
        }else if(res.closeRegister){
            sessionStorage.setItem('canRegister','false');
        }
    }
}